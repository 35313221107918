<template>
  <div
    v-if="!hideSelector"
    class="d-flex">
    <CyMenu
      data-cy="groups-menu"
      offset-y>
      <template #activator="{ on }">
        <span>
          <CyButton
            theme="primary"
            variant="tertiary"
            icon="segment"
            data-cy="group-button"
            icon-prepend
            class="group-by"
            v-on="on">
            {{ $t('groupBy', { group: getGroupLabel(selectedGroup) }) }}
          </CyButton>
        </span>
      </template>
      <v-list>
        <v-list-item
          v-for="group in groups"
          :key="group.groupBy"
          :class="{ 'group-list-item--active': selectedGroup && group.groupBy === selectedGroup.groupBy }"
          data-cy="group-list-item"
          @click="toggleSelectedGroup(group)">
          <v-list-item-title>{{ group.label || group.groupBy }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </CyMenu>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CyDataTableGroups',
  props: {
    groups: {
      type: Array,
      required: true,
    },
    hideSelector: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters('layout', [
      'getDataTableProps',
    ]),
    selectedGroup: {
      get () {
        const groupBy = this.getDataTableProps(this.$route.name)?.groupBy || []
        if (_.isEmpty(groupBy)) return null
        const groupIdentifier = _.head(groupBy).replace(':groupByDuplicate', '')
        return _.find(this.groups, ['groupBy', groupIdentifier])
      },
      set (group) {
        const { name } = this.$route
        const groupBy = !_.isEmpty(group) ? [`${group.groupBy}${group.hideColumn ? '' : ':groupByDuplicate'}`] : []
        this.SET_DATA_TABLE_PROPS({ name, props: { ...this.getDataTableProps(name), groupBy } })
      },
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations('layout', [
      'SET_DATA_TABLE_PROPS',
    ]),
    init () {
      const selectedByDefault = _.find(this.groups, 'isDefault')
      if (selectedByDefault) this.selectedGroup = selectedByDefault
    },
    getGroupLabel (group) {
      if (!group) return this.$t('noGroupBy')
      return group.label || group.groupBy
    },
    toggleSelectedGroup (group) {
      if (group.groupBy === this.selectedGroup?.groupBy) this.selectedGroup = []
      else this.selectedGroup = group
    },
  },
  i18n: {
    messages: {
      en: {
        groupBy: 'Group by: {group}',
        noGroupBy: 'No group by',
      },
      es: {
        groupBy: 'Agrupar por: {group}',
        noGroupBy: 'Ningún grupo por',
      },
      fr: {
        groupBy: 'Regrouper par: {group}',
        noGroupBy: 'Aucun regroupement',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .group-by.cy-btn .v-icon {
  font-size: 22px !important;
}

.group-list-item {
  &--active {
    color: get-color("accent");
  }
}
</style>
