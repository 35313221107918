<template>
  <div class="cy-table-cmp">
    <CyNotification
      theme="error"
      :content="errors"
      closeable
      @close="CLEAR_ERRORS(keyPath)"/>

    <v-row
      align="center"
      :class="[`cy-table-cmp-header elevation-${elevation}`, hasFiltersOrSorts ? 'px-4' : 'px-6']"
      no-gutters>
      <transition
        name="slide-fade-left"
        mode="out-in">
        <v-col
          v-if="searchActive || !hasFiltersOrSorts"
          key="search"
          :class="['cy-table-cmp-header-searchbar pa-0', { 'xs4': !hasFiltersOrSorts }]">
          <slot
            name="cy-table-cmp-header-searchbar"
            v-bind="{ _isMounted }">
            <CySearchBox
              ref="headerSearchbar"
              v-model.trim="searchTerm"
              :autofocus="!_isMounted || searchActive"
              :placeholder="searchPlaceholder"
              append-icon="search"
              clearable
              class="search-field"
              data-cy="search-field"
              @click:clear="$toggle.searchActive(false)"
              @blur="$toggle.searchActive(false)"/>
          </slot>
        </v-col>
      </transition>

      <transition name="slide-fade-right">
        <v-col
          v-if="!searchActive"
          key="filters"
          class="cy-table-cmp-header-filters">
          <div class="d-flex align-center">
            <v-icon
              v-if="hasFiltersOrSorts"
              color="primary"
              class="mr-2"
              @click="$toggle.searchActive">
              search
            </v-icon>
            <CyDataTableSorts
              v-if="!_.$isEmpty(sorts)"
              class="mx-2"
              :sorts="sorts"/>
            <CyDataTableGroups
              v-if="!_.$isEmpty(groups)"
              class="mx-2"
              :groups="groups"
              :hide-selector="hideGroupSelector"/>
            <CyDataTableFilters
              v-if="!_.$isEmpty(filters)"
              class="mx-2"
              :filters="filters"/>
            <!--
                FILTERS SLOT
                Filtering and column selection features.
                SCOPE PARAMS:
                - items: collection of results from the BE.
                - fetchAvailable.extraParams: array of params to pass to YD-api function
              -->
            <slot
              v-else
              :items-from-api="items"
              :get-items-from-api-params="fetchAvailable.extraParams"
              name="table-cmp-header-filters"/>
            <v-spacer/>
            <div
              v-if="!searchActive"
              class="cy-table-cmp-header-actions">
              <!--
                ACTIONS SLOT
                Display actions related to the collection.
                * Individual actions : don't necessarily need data from the data-table
                * Bulk actions : need a set of elements selected from the table
              -->
              <slot
                v-bind="{
                  selected,
                  paginate,
                  filteredItemsTableData,
                  isInBulkMode: canBulkSelect,
                }"
                name="table-cmp-header-actions"/>
            </div>
          </div>
        </v-col>
      </transition>
    </v-row>

    <CyDataTableTags
      v-if="hasTags"
      :filters="filters"
      :search-term="searchTerm"
      :working-route="workingRoute"
      :class="`cy-table-cmp-header-tags elevation-${elevation}`"
      @clear-search="searchTerm = ''">
      <template #tag="{ tag, remove }">
        <slot
          name="cy-table-cmp-header-tag"
          :tag="tag"
          :remove="remove"/>
      </template>
    </CyDataTableTags>

    <slot name="cy-table-cmp-header-append"/>

    <v-data-table
      :id="id"
      v-model="selected"
      :class="[
        `elevation-${elevation} cy-table-data`,
        {
          'is-bulk': canBulkSelect,
          'is-empty': _.isEmpty(filteredItemsTableData),
        },
      ]"
      :headers="headers"
      :items="addGroupingDuplicate(filteredItemsTableData, options)"
      :item-key="keyField"
      :loader-height="2"
      :loading="loading"
      :options.sync="options"
      :show-select="canBulkSelect"
      :hide-default-header="_.isEmpty(filteredItemsTableData)"
      hide-default-footer
      selectable-key="isSelectable"
      @update:items-per-page="backToTop">
      <template
        v-for="({ value: headerValue, text }, index) in headers"
        #[`header.${headerValue}`]>
        <div
          :key="index"
          class="d-inline-flex">
          <span
            v-text="text"/>
          <slot
            :header="headerValue"
            name="header-title-append"/>
        </div>
      </template>
      <template #group.header="props">
        <td
          :colspan="headers.length"
          :class="['pl-2 py-3 clickable', { 'is-open': props.isOpen }]"
          @click="props.toggle">
          <v-icon class="mr-1">
            {{ props.isOpen ? 'expand_less' : 'expand_more' }}
          </v-icon>
          <span class="group-title">
            {{ props.group }}
          </span>
          <v-chip
            v-if="!_.head(groups).hideCount"
            x-small
            class="ml-1">
            {{ props.items.length }}
          </v-chip>
          <slot
            v-bind="{ props }"
            name="group-header-title-append"/>
          <span class="group-header-actions">
            <slot
              v-bind="{ props }"
              name="group-header-actions"/>
          </span>
        </td>
      </template>
      <template #item="props">
        <tr
          :key="_.get(props.item, keyField)"
          v-wrap-children-contents-in-link="{
            selector: 'td',
            to: linkBuilder(props.item),
            tabindexGetter,
            isEnabled: hasLinkBuilder,
          }"
          :class="['cy-table-data__row', { 'clickable': hasLinkBuilder, 'is-grouped': !_.isEmpty(options.groupBy) }]"
          :active="props.isSelected"
          data-cy="data-table-row"
          @click="rowClickCallback(props.item)">
          <td v-if="canBulkSelect || singleSelect">
            <v-simple-checkbox
              v-if="canPerformBulkAction(props.item)"
              v-has-rights-to="deleteAction ? [deleteAction, getCanonical(props.item)] : []"
              color="secondary"
              hide-details
              class="bulk-icon"
              data-cy="row-select"
              ripple
              :value="props.isSelected"
              :off-icon="singleSelect ? '$radioOff' : '$checkboxOff'"
              :on-icon="singleSelect ? '$radioOn' : '$checkboxOn'"
              @input="singleSelect ? selectSingleRow($event, props.item) : props.select($event)"
              @click.prevent/>
          </td>
          <!--
            ROW CONTENT SLOT
            The content of each row. Should follow structure defined in headers

            SCOPE PARAMS:
            - Props : object from row slot.
          -->
          <slot
            v-bind="{ props }"
            bodyrow
            name="table-cmp-body-row">
            <td
              v-for="(header, index) in headers"
              :key="index"
              class="text-left">
              {{ props.item[header.value] }}
            </td>
          </slot>
        </tr>
      </template>
      <template slot="no-data">
        <!--
          NO DATA
          data-table component slot made accessible from cy-table-cmp component
        -->
        <slot name="table-cmp-no-data">
          <!-- default slot content -->
          <p class="datatable-info-text">
            {{ loading ? `${$t('forms.loading')}...` : $t('forms.noData') }}
          </p>
        </slot>
      </template>
      <template slot="no-results">
        <!--
          NO RESULTS
          data-table component slot made accessible from cy-table-cmp component
        -->
        <slot name="table-cmp-no-results">
          <!-- default slot content -->
          <p class="datatable-info-text">
            {{ $t('forms.noResults') }}
          </p>
        </slot>
      </template>
      <template #footer>
        <slot name="table-cmp-footer">
          <CyDataTablePagination
            v-if="paginate && filteredItemsTableData.length"
            class="v-data-footer px-4 py-1"
            :items-length="filteredItemsTableData.length"
            :options.sync="options"
            :items-per-page-options="itemsPerPageOptions"/>
        </slot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { getMissingOwnerObject, hasNoOwner } from '@/utils/helpers'
import CyDataTableTags from '@/components/data-table/tags'
import CyDataTableFilters from '@/components/data-table/filters'
import CyDataTableSorts from '@/components/data-table/sorts'
import CyDataTableGroups from '@/components/data-table/groups'
import CySearchBox from '@/components/search-box.vue'
import CyDataTablePagination from '@/components/data-table/pagination'

/**
 * Data table component that consumes ydAPI. It includes a search term
 * filter and support for bulk operations.
 *
 * @param {Object|Array}  fetchAvailable      Options to help populate vuex organization.available items
 *                                              ! If using an Array, ensure the first Object is the one you want to populate `items`
 *                                              Each object should contain the fields:
 *                                                - `keyPath` (e.g. 'projects' or 'project.pipelines')
 *                                                - `extraParams` This is an array to pass optional/additional params (e.g. [options, another_id_needed])
 *                                                  ! They must be correctly ordered.
 *
 * @param {Array}         headers             Headers definition for vuetify datatable.
 *                                              https://vuetifyjs.com/en/components/data-tables#api
 *                                              If empty the datatable will be rendered with no headers
 *
 * @param {Function}      linkBuilder         Function to navigate to a row element detail
 *                                              When called, linkBuilder receives the clicked row element's related data as a parameter
 *
 * @param {Array}         searchableFields    Fields the search bar will use to match values.
 *                                              An array of objects, each containing:
 *                                                - `name` the field name
 *                                                - `label` a label to show
 *                                                - `filter` (optional) a function for custom filtering on this field
 *
 * @param {String}        keyField            Unique value field used to make each row uniquely identifiable
 *
 * @param {Boolean}       bulk                Toggles the bulk operation mode (shows a checkbox for each row)
 *
 * @param {String}        id                  Unique id required for data-table
 *
 * @param {Number}        itemsPerPage        The number of rows shown in the datatable
 *
 * @param {Array}         itemsPerPageOptions The options to populate the rows per page dropdown
 *
 * @param {Array}         filters             An optional array of objects containing the options for each filter component. I.e:
 *                                            [
                                                {
                                                  queryParam: 'user_id',
                                                  type: 'owner',
                                                  label: this.$t('owner')
                                                }
                                              ]
 *                                            `type` (required): determines which filter component to show.
 *                                            `label` (required): is a display name for the filter shown in the table header
 *                                            `queryParam` (optional): the LHS filters param to manipulate in the query
 *                                            there can be other props like `items` for the select filter on events page
 *
 * @param {Array}         groups               An optional array of objects containing the options for each grouping option. I.e:
 *                                            [
                                                { groupBy: 'team', hideColumn: false, isDefault: false, label: 'Team', hideCount: false },
                                                { groupBy: 'owner', hideColumn: true, isDefault: false, label: 'Owner', hideCount: false },
                                              ]
 *                                              `groupBy`: (required) determines which key to group by.
 *                                              `hideColumn`: (required) Can be true|false. determines whether the column sorted on should be hidden
 *                                              `isDefault`: (optional) Can be true|false. Determines if the group is selected by default.
 *                                              `label`: (optional) Display name of group
 *                                              `hideCount`: (optional) Display total items of group
 *
 * @param {Boolean}       hideGroupSelector    An optional prop to hide group by filter button
 *
 * @param {Function}      parseItemsArray     An optional function that will be applied to the items array (before passing through transformItems)
 *
 * @param {Array}         sorts               An optional array of objects containing the options for each sort. I.e:
 *                                            [
                                                { sortBy: 'created_at', descending: false },
                                                { sortBy: 'created_at', descending: true },
                                                { sortBy: 'updated_at', descending: true },
                                              ]
 *                                              `sortBy`: (required) determines which queryParam to sort by.
 *                                              `descending`: (required) Can be true|false. determines the sort direction
 * @param {Function}      transformItems      An optional function that will be applied to all items in the table through an Array.map()
 *
 * @param {Boolean}       immediate           Determines whether data should be fetched immediatly on component mount
 *
 */
export default {
  name: 'CyDataTableYdApi',
  components: {
    CyDataTableTags,
    CyDataTableFilters,
    CyDataTableSorts,
    CyDataTableGroups,
    CySearchBox,
    CyDataTablePagination,
  },
  props: {
    fetchAvailable: {
      type: [Object, Array],
      validator: (val) => {
        if (_.isEmpty(val)) return true
        if (_.isPlainObject(val)) {
          return _.has(val, 'keyPath') &&
            _.isString(val.keyPath) &&
            (_.has(val, 'extraParams') ? _.isArray(val.extraParams) : true)
        }
        if (_.isArray(val)) {
          return _.every(val, (item) => _.has(item, 'keyPath') &&
            _.isString(item.keyPath) &&
            (_.has(item, 'extraParams') ? _.isArray(item.extraParams) : true),
          )
        }
      },
      required: true,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    hideGroupSelector: {
      type: Boolean,
      default: false,
    },
    linkBuilder: {
      type: Function,
      default: () => { /* silenced */ },
    },
    searchableFields: {
      type: Array,
      validator: (searchableFields) => _.isEmpty(searchableFields) ||
        _.every(searchableFields, (field) => _.$hasAll(field, ['name', 'label'])),
      default: () => [],
    },
    keyField: {
      type: String,
      default: '',
    },
    bulk: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'cy-table-cmp',
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [10, 25, 50, 100],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      validator: (groups) => _.isEmpty(groups) ||
        _.every(groups, (group) => _.$hasAll(group, ['groupBy', 'hideColumn'])),
      default: () => [],
    },
    paginate: {
      type: Boolean,
      default: true,
    },
    parseItemsArray: {
      type: Function,
      default: (items) => items,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    sorts: {
      type: Array,
      default: () => [],
    },
    transformItems: {
      type: Function,
      default: (item) => item,
    },
    value: {
      type: Array,
      default: () => [],
    },
    workingRoute: {
      type: Object,
      default () {
        return this.$route
      },
    },
    rowClickCallback: {
      type: Function,
      default: () => { /* silenced */ },
    },
    elevation: {
      type: [String, Number],
      default: 1,
    },
    customSearchPlaceholder: {
      type: String,
      default: null,
    },
    immediate: {
      type: Boolean,
      default: true,
    },
    prefilters: {
      type: Object,
      default: null,
    },
  },
  data: ({ itemsPerPage, value }) => ({
    items: [],
    searchActive: false,
    selected: value,
    loading: false,
    dataTable: {
      descending: false,
      itemsPerPage,
      sortBy: ['name'],
    },
  }),
  computed: {
    ...mapState({
      organizations: (state) => state.organizations,
      orgsErrors: (state) => state.errors.organizations,
    }),
    ...mapState('organization', {
      available: (state) => state.available,
      orgDependentErrors: (state) => state.errors,
    }),
    ...mapGetters('layout', [
      'getDataTableProps',
      'getDataTableFilters',
    ]),
    searchTerm: {
      get () {
        return _.get(this.getDataTableProps(this.workingRoute.name), 'searchTerm', '')
      },
      set (searchTerm) {
        const { name } = this.workingRoute
        this.SET_DATA_TABLE_PROPS({ name, props: { ...this.getDataTableProps(name), searchTerm } })
      },
    },
    errors () {
      return this.isOrgsList
        ? this.orgsErrors
        : _.get(this.orgDependentErrors, this.keyPath)
    },
    isOrgsList () {
      return this.keyPath === 'organizations'
    },
    keyPath () {
      return _.isArray(this.fetchAvailable)
        ? this.fetchAvailable[0].keyPath
        : this.fetchAvailable.keyPath
    },
    options: {
      get () {
        const { getDataTableProps, dataTable, workingRoute } = this
        return { ...dataTable, ...getDataTableProps(workingRoute.name) }
      },
      set ({ itemsPerPage, page, groupBy = [], ...rest }) {
        const computedItemsPerPage = this.paginate ? itemsPerPage : -1
        const { searchTerm, workingRoute: { name } } = this
        this.dataTable = { ...rest, itemsPerPage: computedItemsPerPage }
        this.SET_DATA_TABLE_PROPS({ name, props: { itemsPerPage: computedItemsPerPage, page, filters: this.activeFilters, searchTerm, groupBy } })
      },
    },
    hasLinkBuilder () {
      return !_.isEmpty(this.linkBuilder()) && this.linkBuilder?.name !== '_default'
    },
    filteredItemsTableData () {
      const sortedItems = _.sortBy(this.items, [
        'name',
        ({ given_name: firstName = '' }) => firstName.toLowerCase(),
        ({ family_name: lastName = '' }) => lastName.toLowerCase(),
        'username',
        'id',
      ])
      if (!this.searchTerm) return sortedItems

      const searchTerm = this.searchTerm.toLowerCase()

      const items = sortedItems.filter((item) => {
        let meetsFilter = false

        for (const { filterFunction, name } of this.searchableFields) {
          const fieldValue = item[name] || ''

          const cannotBeFiltered = (_.$isEmpty(fieldValue) || _.isObject(fieldValue)) && !filterFunction
          if (cannotBeFiltered) continue

          if (filterFunction) {
            const filteredSearch = String(filterFunction(fieldValue)).toLowerCase().includes(searchTerm)
            meetsFilter = meetsFilter || filteredSearch
          } else {
            const matchesSearch = () => String(fieldValue).toLowerCase().includes(searchTerm)
            meetsFilter = meetsFilter || matchesSearch()
          }
        }

        return meetsFilter
      })

      return items
    },
    searchPlaceholder () {
      if (this.customSearchPlaceholder) return this.customSearchPlaceholder
      const searchBy = this.$t('forms.searchBy')
      const labels = _.map(this.searchableFields, 'label')
      const conjunction = this.$t('or')
      const listOfLabels = _.$getListFromArray(labels, { conjunction })
      return `${searchBy} ${listOfLabels}`
    },
    activeFilters () {
      return this.getDataTableFilters(this.workingRoute.name)
    },
    hasTags () {
      return !_.$isEmpty(this.activeFilters) || !_.$isEmpty(this.searchTerm)
    },
    hasFiltersOrSorts () {
      return !_.$isEmpty(this.filters) || !_.$isEmpty(this.sorts)
    },
    deleteAction () {
      const { delete: deleteAction, unassign } = this.workingRoute.meta.actions
      return unassign || deleteAction
    },
    canBulkSelect () {
      return this.bulk && !this.singleSelect && !_.isEmpty(this.items.filter(({ _isDefault }) => !_isDefault))
    },
  },
  watch: {
    activeFilters: {
      async handler (newVal, oldVal) {
        if (_.isEqual(JSON.stringify(newVal), JSON.stringify(oldVal))) return
        await this.retrieveItems({ updateFilters: true })
      },
      deep: true,
    },
    selected (selected) {
      this.$emit('input', selected)
    },
  },
  created () {
    if (this.immediate) this.retrieveItems({ updateFilters: true })
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapMutations('organization', [
      'CLEAR_ERRORS',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_PROPS',
    ]),
    async retrieveItems ({ clearErrors = true, clearSelected = false, updateFilters = false } = {}) {
      this.loading = true

      if (clearSelected) this.selected = []

      if (!_.isEmpty(this.fetchAvailable)) {
        const fetchAvailable = _.isArray(this.fetchAvailable) ? this.fetchAvailable : [this.fetchAvailable]
        for (const { keyPath, extraParams = [] } of fetchAvailable) {
          const filterQueryObject = this.getFilterQueryObject()
          const params = _.isEmpty(filterQueryObject)
            ? [...extraParams]
            : [...extraParams, _.cloneDeep(filterQueryObject)]
          await this.FETCH_AVAILABLE({ keyPath, clearErrors, updateFilters, ...(!_.isEmpty(params) ? { extraParams: params } : {}) })
        }
        const data = this.isOrgsList
          ? this.organizations
          : _.get(this.available, this.keyPath)

        this.setDataTableItems(data)
      }

      this.loading = false
      this.$emit('loaded')
    },
    getFilterQueryObject () {
      if (!this.prefilters) return this.activeFilters
      return _.mergeWith(_.cloneDeep(this.activeFilters), this.prefilters, (filterValueString, prefilterValueString, key) => {
        if (_.isEmpty(filterValueString)) return prefilterValueString
        if (_.isEmpty(prefilterValueString)) return filterValueString
        const [filterValues, prefilterValues] = [filterValueString, prefilterValueString].map((string) => string.split(','))
        const validValues = filterValues.filter((value) => prefilterValues.includes(value)).join(',')
        return validValues === '' ? prefilterValueString : validValues
      })
    },
    setDataTableItems (data = []) {
      const isListThatShowsOwner = _.some(this.headers, ({ value }) => value.includes('owner'))
      const findMissingOwners = (item) => ({ ...item, owner: hasNoOwner(item.owner) ? { ...item.owner, ...getMissingOwnerObject() } : item.owner })
      const untransformedItems = isListThatShowsOwner
        ? _.cloneDeep(data).map(findMissingOwners)
        : _.cloneDeep(data)
      this.items = this.parseItemsArray(untransformedItems)
        .map(this.transformItems)
        .map((item) => _.merge(item, { isSelectable: this.canPerformBulkAction(item) }))

      this.$emit('change', this.items)
    },
    addGroupingDuplicate (items, options) {
      if (!options.groupBy) return items
      const [groupBy] = options?.groupBy
      if (!groupBy || !_.isString(groupBy) || !groupBy.endsWith(':groupByDuplicate')) return items

      const groupByValue = groupBy.replace(':groupByDuplicate', '')
      return items.map((item) => ({ ...item, [`${groupByValue}:groupByDuplicate`]: item[groupByValue] }))
    },
    getCanonical ({ canonical, id }) {
      const { name } = this.workingRoute
      return name !== 'members' ? canonical : id
    },
    canPerformBulkAction (item) {
      return !item._isDefault
    },
    backToTop () {
      window.scrollTo(0, 0)
    },
    tabindexGetter (currentIndex) {
      const focusableCellIndex = this.canBulkSelect ? 1 : 0
      return currentIndex === focusableCellIndex ? 0 : -1
    },
    // eslint-disable-next-line vue/no-unused-properties
    clearSelection () {
      this.selected = []
    },
    selectSingleRow (selected, item) {
      this.selected = selected ? [item] : []
    },
  },
}
</script>

<style lang="scss" scoped>
  @keyframes slide-in {
    0% {
      transform: scaleX(0);
    }

    100% {
      transform: scaleX(1);
    }
  }

  .cy-table-cmp {
    width: 100%;

    ::v-deep .cy-notification {
      margin-top: 1em;
    }

    &-header {
      min-height: 60px;
      margin: 0 !important;
      border-radius: 4px 4px 0 0;
      background-color: get-color("white");

      &-searchbar {
        ::v-deep .v-input__slot {
          margin-bottom: 0;
        }

        ::v-deep .v-text-field {
          margin-top: 2px !important;
        }

        .input-group__details {
          height: 5px !important;
        }
      }

      &-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  .cy-table-data {
    border-radius: 0 0 4px 4px;

    .v-data-table,
    .v-data-table__actions {
      border-radius: 0 0 4px 4px;
    }

    ::v-deep {
      .v-row-group__header {
        background-color: white;

        > td {
          &.is-open {
            border-bottom: thin solid rgba(0 0 0 / 12%);
          }

          .group-title {
            color: get-color("primary", "dark-1");
            font-weight: $font-weight-bold;
          }
        }

        .group-header-actions {
          float: right;
        }
      }

      .cy-table-data__row.is-grouped {
        background-color: get-color("grey", "light-4");
      }
    }

    &.is-bulk {
      th:first-child,
      td:first-child {
        width: 84px;
      }

      tr[active] {
        background: get-color("secondary", "light-4");

        &:hover {
          background: get-color("secondary", "light-3") !important;
        }
      }
    }

    &:not(.is-bulk) ::v-deep {
      tr:not(.v-data-table__progress, .v-row-group__header) {
        th:first-child,
        td:first-child {
          &:not([class*="pl-"]) {
            padding-left: 40px !important;
          }
        }
      }

      thead,
      tr.clickable {
        th:first-child,
        td:first-child {
          padding-left: 36px;
        }
      }

      tr.clickable {
        &:hover {
          background-image: url("/static/images/zoom_in.png") !important;
          background-repeat: no-repeat !important;
          background-position: 10px 15px !important;
          background-size: 18px 18px !important;
        }
      }
    }

    &__row ::v-deep td > * {
      vertical-align: middle;
    }
  }

  ::v-deep .v-table__overflow {
    .v-data-table {
      border-radius: 0;
    }
  }

  .datatable-info-text {
    margin: 0;
    padding: 1.5em;
    text-align: center;
  }

  .is-bulk {
    ::v-deep thead .v-icon,
    .bulk-icon ::v-deep .v-icon {
      color: get-color("grey");
    }

    .bulk-icon {
      margin-top: 0;
    }
  }

  .slide-fade-left {
    &-enter-active {
      transform-origin: left center;
      transition: all 0.6s ease-in;
      animation: slide-in 0.6s;
    }

    &-enter {
      transform: translateX(-20px) !important;
      opacity: 0;
    }

    &-leave,
    &-leave-active {
      transition: none;
    }
  }

  .slide-fade-right {
    &-enter-active {
      transform-origin: right center;
      transition: all 0.45s ease;
    }

    &-enter {
      transform: translateX(20px) !important;
      opacity: 0;
    }

    &-leave,
    &-leave-active {
      transition: none;
    }
  }

  ::v-deep .router-link-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 -16px;
    padding: 0 16px;
  }
</style>
